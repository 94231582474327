<div class="login-page">
  <nav
    class="navbar navbar-color-on-scroll navbar-transparent fixed-top navbar-expand-lg"
    color-on-scroll="100"
    id="sectionsNav"
  >
    <div class="container">
      <div class="navbar-translate">
        <a class="navbar-brand text-white">Facilitor | Admin</a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
          <span class="navbar-toggler-icon"></span>
        </button>
      </div>
      <div class="collapse navbar-collapse"></div>
    </div>
  </nav>
  <div
    class="page-header header-filter"
    style="
      background-image: url('../../assets/img/admin-bg.png');
      background-size: cover;
      background-position: top center;
    "
  >
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-sm-6 ml-auto d-flex" style="min-height: 100vh">
          <div class="card card-signup align-self-center">
            <form class="form" [formGroup]="loginForm">
              <div class="card-header card-header-primary text-center">
                <h4 class="card-title">Login</h4>
              </div>
              <p class="description text-center">Or Be Classical</p>
              <div class="form-group">
                <div class="input-group mb-4">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">mail</i>
                    </span>
                  </div>
                  <input
                    type="email"
                    class="form-control"
                    placeholder="Email"
                    name="email"
                    formControlName="email"
                    name="email"
                    email="true"
                  />
                </div>
                <!-- <div *ngIf="!submitted" class="email-error">
                <div *ngIf="f.email.hasError('pattern')" class="text-danger">
                  Invalid Email Format.
                </div>
               </div> -->
                <div
                  *ngIf="submitted && f.email.errors && !f.password.errors"
                  class="email-error"
                >
                  <div *ngIf="f.email.errors.required" class="text-danger">
                    Email is required !
                  </div>

                  <div
                    *ngIf="
                      !f.email.errors.required &&
                      f.email.errors.status === INVALID
                    "
                    class="text-danger"
                  >
                    Invalid email format!
                  </div>
                </div>

                <div
                  *ngIf="
                    submitted &&
                    f?.email?.errors &&
                    f?.password?.errors?.required
                  "
                  class="email-error"
                >
                  <div *ngIf="f?.email?.errors?.pattern" class="text-danger">
                    Invalid email format!!
                  </div>
                </div>

                <!-- <div *ngIf="submitted && f.email.errors.pattern"  class="email-error">
                  Email format invalid !
                </div> -->
                <div>
                  <!-- <p class="text-danger text-center">
                    Invalid Email and password.
                  </p> -->
                  <!-- <div *ngIf="submitted && f.email.errors" class=" text-left">
                    <div *ngIf="f.email.errors.required" class="text-danger text-center">
                      Email is required !
                    </div>
                  </div> -->
                </div>

                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text">
                      <i class="material-icons">lock_outline</i>
                    </span>
                  </div>
                  <input
                    type="password"
                    class="form-control"
                    placeholder="Password"
                    formControlName="password"
                    name="password"
                  />
                </div>
                <div
                  *ngIf="
                    (submitted && f.password.errors && !f.email.errors) ||
                    (submitted && f.password.errors && f.email.errors.pattern)
                  "
                  class="password-error"
                >
                  <div *ngIf="f.password.errors.required" class="text-danger">
                    Password is required !
                  </div>
                </div>
                <!-- <div *ngIf="submitted && f.email.errors.pattern" class="password-error">
                  <div *ngIf="f.password.errors.required" class="text-danger ">
                    Password is required !
                  </div>
                </div> -->
                <!-- <div *ngIf="submitted && f.password.errors" class=" text-left">
                  <div *ngIf="f.password.errors.required" class="text-danger text-center">
                    Password is required !
                  </div>
                </div> -->
                <div *ngIf="submitted && f.email.errors && f.password.errors">
                  <div
                    *ngIf="f.email.errors.required"
                    class="text-danger both-error"
                  >
                    Please enter email and password
                  </div>
                </div>
              </div>

              <!-- <p class="text-danger text-center" [hidden]="invalid">
                Invalid Email and password.
              </p> -->
              <div class="footer">
                <div class="w-100 text-center">
                  <a class="btn btn-primary text-white" (click)="login()"
                    >Submit
                  </a>
                  <a class="btn btn-link" (click)="forgotPassword()"
                    ><sub><i>Forgot Password ?</i></sub>
                  </a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
