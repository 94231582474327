import { Injectable } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import {Observable} from 'rxjs/Rx';
// import { of } from 'rxjs/observable/of';
import { throwError } from 'rxjs';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
// import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private router: Router) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let token = localStorage.getItem('token');

    if (token) {
      let httpRequest = request.clone({ setHeaders: { Authorization: token } });
      // return next.handle(httpRequest);
      return next.handle(httpRequest)
        .pipe(catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              localStorage.clear();
              this.router.navigate(['/login']);
              console.log('this should print your error!', err.error);
            }
            // return Observable.throw(err.statusText)
            return throwError( err )
          }
        }));
    }
    else
      // (error:any) => {
      //   console.log(error,"eeerorrr")
      // if(error instanceof HttpErrorResponse){
      //    if(error && error.status === 401 ){
      //      localStorage.clear();
      //      this.router.navigate(['/login'])
      //    }
      // }
      // }
      return next.handle(request);

  }
}
