import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { HttpcommanService } from "./httpshared.service";
@Injectable({
  providedIn: 'root'
})
export class ResetPasswordService {

  constructor(
    private http: HttpClient,
    private httpService: HttpcommanService
  ) {}
resetPassword(data){
    return this.httpService.postCall("admin/auth/reset-password", data);
  }
}
