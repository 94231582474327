<div class="sidebar" data-color="purple" data-background-color="white">
  <div class="logo">
    <!-- <a href="http://www.creative-tim.com" class="simple-text logo-mini">
      CT
    </a> -->
    <a [routerLink]="['/dashboard']" class="simple-text logo-normal">
      Facilitor | Admin
    </a>
  </div>
  <div class="sidebar-wrapper">
    <ul class="nav">
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/dashboard']">
          <i class="material-icons">dashboard</i>
          <p>Dashboard</p>
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/admins']">
          <!-- <i class="material-icons">people</i> -->
          <!-- <p>Admin Management</p> -->

          <img src="../../../../../assets/img/Admin.png" alt="" />
          <span>Administrators</span>
        </a>
      </li>

      <!-- <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/users']">
                    <i class="material-icons">people</i>
                    <p>All Users</p>
                </a>
            </li> -->

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/members']">
          <i class="material-icons">people</i>
          <p>Platform Members</p>
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/financial-verifications']">
          <i class="material-icons">check</i>
          <p>Financial Verifications</p>
          &nbsp;
          &nbsp;
          <!-- <span class="white_circle">
            <img src="../../../../../assets/img/white.png" alt=""
          /></span> -->
          <span
            class="white_circle"
            *ngIf="docsData?.unreadCountForFinancialVerification !== 0"
          >
            <img src="../../../../../assets/img/red_circle.png" alt=""
          /></span>
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/facilitors']">
          <!-- <i class="material-icons">people</i> -->
          <img src="../../../../../assets/img/facilitorpartners.png" alt="" />
          <p>Facilitor Partners</p>
          &nbsp;
          &nbsp;
          <span
          class="white_circle"
          *ngIf="docsData?.unreadCountForFacilitor !== 0"
        >
          <img src="../../../../../assets/img/red_circle.png" alt=""
        /></span>
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/background']">
          <!-- <i class="material-icons">check</i> -->
          <img
            src="../../../../../assets/img/Background_verification.png"
            alt=""
          />
          <p>Background Verification</p>
          &nbsp;
          &nbsp;
          <!-- <span class="white_circle">
            <img src="../../../../../assets/img/white.png" alt="" />
          </span> -->
          <span
            class="white_circle"
            *ngIf="docsData?.unreadCountForBackgroundVerification !== 0"
          >
            <img src="../../../../../assets/img/red_circle.png" alt=""
          /></span>
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/property']">
          <i class="material-icons">real_estate_agent</i>
          <p>Platform Properties</p>
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/transaction']">
          <i class="material-icons">account_balance</i>
          <p>Facilitor Transaction Management</p>
        </a>
      </li>

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/member-transaction/member-transaction-management']">
          <i class="material-icons">account_balance_wallet</i>
          <p>Member Transaction management </p>
        </a>
      </li>

      <!-- <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/supportTicket']">
          <i class="material-icons">help_center</i>
          <p>Support Ticket</p>
        </a>
      </li> -->

      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/attorney']">
          <i class="material-icons">gavel</i>
          <p>Advertised Attorney</p>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/third-party-attorney']">
          <!-- <i class="material-icons">gavel</i> -->
          <img src="../../../../../assets/img/member's_ attorney.png" alt="" />
          <p>Member’s Attorney</p>
        </a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" [routerLink]="['/fee']">
          <i class="material-icons">account_balance_wallet</i>
          <p>Fee</p>
        </a>
      </li>
      <!-- <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/category']">
                    <i class="material-icons">category</i>
                    <p>Category Management</p>
                </a>
            </li> -->

      <!--
            <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/report']">
                    <i class="material-icons">reports</i>
                    <p>Reports & Analysis</p>
                </a>
            </li> -->

      <!-- <li class="nav-item " routerLinkActive="active">
                <a class="nav-link" [routerLink]="['/common/change-password']">
                    <i class="material-icons">fingerprint</i>
                    <p>Change Password</p>
                </a>
            </li> -->
    </ul>
  </div>
</div>
