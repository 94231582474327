import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SharedService } from "../../services/shared.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup } from '@angular/forms';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit {
  firstName: string = localStorage.getItem('first_name');
  lastName: string = localStorage.getItem('last_name');
  constructor(private _route: Router, private sharedService: SharedService, private matSnackBar: MatSnackBar, private fb: FormBuilder) { }
  myFiles: string[] = [];
  imageSrc: string=''
  //imageForm: FormGroup;
  editAdminProfileForm: FormGroup =  this.fb.group({
    first_name: [''],
    last_name: [''],
  //  profile_pic: ['', Validators.required]
  });
  invalid: any = true;
  submitted = false;
  profilePicture:any;
  imagePath = environment.imagePath;
  ngOnInit() {
    console.log(this.firstName,this.lastName)
    this.sharedService.getProfilePicture().subscribe(
      result =>{
        this.imageSrc =  this.imagePath + result?.data?.profile_pic;
        this.profilePicture =  result?.data?.profile_pic;
      }
    );
    // this.editAdminProfileForm.patchValue({
    //   first_name: user.first_name,
    //   last_name: user.last_name,
    //   phone_number: user.phone_number
    // })
  }
  // buildForm() {
  //   // this.editAdminProfileForm = this.fb.group({
  //   //   first_name: ['', [Validators.required]],
  //   //   last_name: ['', [Validators.required]],
  //   // //  profile_pic: ['', Validators.required]
  //   // });
  // }
  get adminFormControls() {
    return this.editAdminProfileForm.controls;
  }
  userId: number = 0;
  editUser() {
    this.editAdminProfileForm.patchValue({
      first_name: localStorage?.first_name,
      last_name: localStorage?.last_name,
    })
  }
  popUpClosed(){
    //this.editFlag=false;
    this.editAdminProfileForm?.reset();
    let ele = document.getElementById("adminAddClosePopUpID");
    ele?.click();
  }
  editForm() {
    let editData = new FormData();
    editData.append('first_name',this.editAdminProfileForm.value.first_name);
    editData.append('last_name',this.editAdminProfileForm.value.last_name);
    for (var i = 0; i < this?.myFiles?.length; i++) {
      editData.append("profile_pic", this.myFiles[i]);
    }
    console.log("editAdminProfileForm value",this.editAdminProfileForm.value);
    
    this.sharedService.updateAdminProfile(editData).subscribe(
      result => {
        console.log(result, "result__1")
       

        if (result.status == 200) {
          console.log(this.editAdminProfileForm.value.first_name,this.editAdminProfileForm.value.last_name )
          localStorage.setItem('first_name', this.editAdminProfileForm.value.first_name);
          localStorage.setItem('last_name', this.editAdminProfileForm.value.last_name);
          this.myFiles = [];
          this.matSnackBar.open('Profile Updated Successfully.', 'ok', { duration: 2000 });
          this.popUpClosed();
          // this.refresh();
          window.location.reload()
        //  this._route.navigate(['/admins/list']);
        }
      },
      err => {
        if (err) {
          console.log(err, "error");
          this.matSnackBar.open('Could Not Update Profile.', 'ok', { duration: 2000 });
        }
      }
    );
  }
  getFileDetails(e) {
    const reader = new FileReader();
    for (let i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);

      reader.onload = () => {

        this.imageSrc = reader.result as string;

        // this.imageForm.patchValue({
        //   fileSource: reader.result
        // });

      };
    }
  }
  // refresh() {
  //   this._route.navigateByUrl('/admins/list', { skipLocationChange: true }).then(() => {
  //     this._route.navigate(['/admins/list']);
  //   })
  // }
  //[routerLink]="['/logout']"
  logout() {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to logout!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4CAF50',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, Logout!'
    }).then((result) => {
      if (result.isConfirmed) {
        sessionStorage.clear();
        localStorage.setItem('token', null);
        localStorage.setItem('isLoggedIn', 'false');
        localStorage.clear();
        this._route.navigate(['/login']);
      }
    })


  }


}
