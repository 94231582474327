import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'phoneFormat' })
export class Customphoneformat implements PipeTransform {
    transform(localNumber: any): any {
        // console.log('localNumber',localNumber)
        var value = localNumber.toString().trim().replace(/^\+/, "")
        value = value.split("-").join('')
        value = value.split(" ")
        console.log('value',value)
        var newArray = value[1].match
        (/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    // Resolving the above array we get
    // the international number
    var internationalNumber = '+' + (value[0]!== 'undefined' ?value[0]:1 )+
        ' (' + newArray[2] + ') ' + newArray[3]
        + '-' + newArray[4];
      return internationalNumber
    }
}
