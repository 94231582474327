import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { LoginComponent } from './login/login.component';
import { SharedModule } from './modules/shared/shared.module';
import { AngularmaterialModule } from './modules/angularmaterial/angularmaterial.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { httpInterceptorProvider } from './providers/httpInterceptor';
import { hashLocation } from './providers/hashLocation';
import { NgxPaginationModule } from 'ngx-pagination';
import { JwtService } from './services/jwt.services';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
// import { Customphoneformat } from "../services/changePhoneNumberFormat.pipe";

//import { NgxSpinnerModule } from "ngx-spinner";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularmaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    BrowserAnimationsModule,
    SharedModule,
    NgxPaginationModule,
    Ng2SearchPipeModule,

    // NgxSpinnerModule,
    NgHttpLoaderModule.forRoot(), // <============ Don't forget to call 'forRoot()'!
  ],
  exports: [AngularmaterialModule, SharedModule],
  providers: [hashLocation, httpInterceptorProvider, JwtService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
