<nav
  class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top"
>
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <a class="navbar-brand" href="#pablo"></a>
    </div>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      aria-controls="navigation-index"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end">
      <ul class="navbar-nav">
        <!-- <li class="nav-item">
                    <button class="btn btn-sm btn-danger" (click)="logout()"> <i class="material-icons">logout</i> Logout </button>
                </li> -->
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            data-toggle="dropdown"
            href="#"
            aria-expanded="false"
          >
            <span class="profile-name-wrap">
              <span class="profile-img">
                <img
                  *ngIf="profilePicture"
                  src="{{ imagePath + profilePicture }}"
                />
                <img
                  *ngIf="!profilePicture"
                  src="../../../../../assets/img/new_logo.png"
                />
              </span>
              {{ firstName }} {{ lastName }}</span
            >
          </a>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-right"
            style="left: inherit; right: 0px"
          >
            <a class="dropdown-item top-nav-drop-item">
              <p
                data-toggle="modal"
                data-target="#editadmin"
                data-backdrop="static"
                data-keyboard="false"
                (click)="editUser()"
              >
                <!-- <button data-toggle="modal" data-target="#editadmin" data-backdrop="static" data-keyboard="false"  (click)="editUser()">  -->
                <i class="fas fa-pen"></i> &nbsp;<span>Edit Profile</span>
                <!-- </button> -->
              </p>
            </a>

            <a class="dropdown-item top-nav-drop-item">
              <p [routerLink]="['/common/change-password']">
                <i class="material-icons">fingerprint</i>Change Password
              </p>
            </a>
            <a class="dropdown-item top-nav-drop-item">
              <p (click)="logout()">
                <i class="material-icons">logout</i>Log Out
              </p>
            </a>
            <!-- <a class="dropdown-item top-nav-drop-item">
                <input type="file"  id="myFile"  (change)="getFileDetails($event)">
                <button (click) = "addImage()">Upload</button>
                <i class="material-icons">fingerprint</i>Upload Image
            </a> -->
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- End Navbar -->

<div
  class="modal fade"
  id="editadmin"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header" style="background-color: #a438b8">
        <button
          type="button"
          class="close close-new"
          data-dismiss="modal"
          aria-label="Close"
          id="adminAddClosePopUpID"
          (click)="popUpClosed()"
        >
          <span aria-hidden="true" class="close-cross">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="editAdminProfileForm" (ngSubmit)="editForm()">
          <div>
            <input
              type="file"
              accept="image/*"
              id="myFile"
              (change)="getFileDetails($event)"
            />
            <div class="profileImgContainer">
              <img [src]="imageSrc" *ngIf="imageSrc" />
            </div>
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">First Name*</label>
            <input
              type="text"
              class="form-control"
              id="first_name"
              aria-describedby="emailHelp"
              placeholder="Enter First Name"
              formControlName="first_name"
            />
            <label
              *ngIf="
                adminFormControls?.first_name?.touched &&
                adminFormControls?.first_name?.errors?.required
              "
              class="text-danger text-center"
              class="text-danger"
              >First Name is required</label
            >
          </div>
          <div class="form-group">
            <label for="exampleInputEmail1">Last Name*</label>
            <input
              type="text"
              class="form-control"
              id="last_name"
              aria-describedby="emailHelp"
              placeholder="Enter Last Name"
              formControlName="last_name"
            />
            <label
              *ngIf="
                adminFormControls?.last_name?.touched &&
                adminFormControls?.last_name?.errors?.required
              "
              class="text-danger"
              >Last Name is required</label
            >
          </div>
          <button type="submit" class="btn btn-primary">Submit</button>
        </form>
      </div>
    </div>
  </div>
</div>
