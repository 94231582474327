import { Component, OnInit } from '@angular/core';
import { LoginService } from '../services/login.service';
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt.services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  invalid: any = true;
  submitted = false;
  email: any;
  password: any;
  loginForm: FormGroup;
  constructor(private _login: LoginService, private _route: Router, private formBuilder: FormBuilder, private snack: MatSnackBar) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      // /^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
      email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-zA-Z]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$')]],
      // email: ['', [Validators.required, Validators.email,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', [Validators.required]],
    });
  }


  get f() {
    console.log(this.loginForm.controls);
    return this.loginForm.controls;
  }
  forgotPassword(){
    this._route.navigate([`/forgotPassword`])
  }
  login() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    let login = {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password
    }
    console.log(login);
    this._login.login(login).subscribe(
      result => {
        console.log("check result", result);

        if (result.status == 200) {
          if(!result.data.user.is_account_active){
            this.snack.open('Your account is not active', 'ok', { duration: 5000 })
          }
          else{
            localStorage.setItem('isLoggedIn', 'true');
            localStorage.setItem('token', result.data.token);
            localStorage.setItem('first_name', result.data.user.first_name);
            localStorage.setItem('last_name', result.data.user.last_name);
            this._route.navigate(['/dashboard']);
          }
        } else this.invalid = false;
      },
      err => {
        if (err) {
          console.log(err?.error?.message,"hhh");

          if(err.error.status = 401){
            this.snack.open(err?.error?.message, 'ok', { duration: 5000 });
          }
          else{
          this.snack.open('Invalid email or password', 'ok', { duration: 5000 });
          }
        }
        this.invalid = false;
      }
    );
  }
}
