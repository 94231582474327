<div class="wrapper ">
    <app-sidebar></app-sidebar>
    <div class="main-panel">
      <!-- Navbar -->
      <app-header></app-header>
      <div class="content">
        <div class="container-fluid">
          <div class="row" style="margin-top:-50px;">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title ">Notification Broadcasting</h4>
                  <!-- <p class="card-category">Here is a subtitle for this table</p> -->
                </div>
                <div class="card-body">
                  <form [formGroup]="broadcastForm">
                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">Title</label>
                          <input type="text" formControlName="title" name="title" class="form-control" />
                        </div>
                        <div *ngIf="submitted && f.title.errors" class=" text-left">
                          <div *ngIf="f.title.errors.required" class="validation_color text-danger">
                            Title is required !
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">Message</label>
                          <textarea rows="5" class="form-control" formControlName="message" name="message" >
                              </textarea>
                        </div>
                        <div *ngIf="submitted && f.message.errors" class=" text-left">
                          <div *ngIf="f.message.errors.required" class="validation_color text-danger">
                            Message is required !
                          </div>
                        </div>
                      </div>
                    </div>
                 
  
                    <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-2">
                        <div class="form-group">
                          <button class="btn btn-primary btn-md" (click)="saveDetails()">Send</button>
                        </div>
                      </div>
                      <div class="col-md-1"></div>
                      <div class="col-md-2"></div>
                    </div>
                    <br />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  