import { NgModule } from '@angular/core';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { SharedRoutingModule } from './shared-routing.module';
import { AngularmaterialModule } from '../angularmaterial/angularmaterial.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ChangepasswordComponent } from './components/changepassword/changepassword.component';
import { BroadcastingComponent } from './components/broadcasting/broadcasting.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DateTimePickerComponent } from './components/date-time-picker/date-time-picker.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library as fontLibrary } from '@fortawesome/fontawesome-svg-core';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { Customphoneformat } from '../../../services/changePhoneNumberFormat.pipe';
fontLibrary.add(faCalendar, faClock);

@NgModule({
  declarations: [
    SidebarComponent,
    HeaderComponent,
    ChangepasswordComponent,
    BroadcastingComponent,
    DateTimePickerComponent,
    FeedbackComponent,
    Customphoneformat,
  ],
  imports: [
    SharedRoutingModule,
    AngularmaterialModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    MatSlideToggleModule,

    NgxPaginationModule,
  ],
  exports: [
    SidebarComponent,
    HeaderComponent,
    AngularmaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatSlideToggleModule,
    DateTimePickerComponent,

    ChangepasswordComponent,
    NgxPaginationModule,
    Customphoneformat,
  ],
})
export class SharedModule {}
