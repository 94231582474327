import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { PreloadAllModules } from '@angular/router';
import { ProtectGuard } from './guards/protect.guard';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AuthGuard } from './guards/auth.guard';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '*', redirectTo: 'login', pathMatch: 'full' },
  { path: 'forgotPassword', component: ForgotPasswordComponent },
  { path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'login',
    canActivate: [ProtectGuard],
    component: LoginComponent,
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'common',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/shared/shared.module').then((m) => m.SharedModule),
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/users/users.module').then((m) => m.UsersModule),
  },
  {
    path: 'members',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/member/members.module').then((m) => m.MembersModule),
  },
  {
    path: 'member-transaction',
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/member-transaction/member-transaction.module').then((m) => m.MemberTransactionModule),
  },
  {
    path: 'admins',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/admin/admins.module').then((m) => m.AdminsModule),
  },
  {
    path: 'financial-verifications',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/financial-verification/financial-verifications.module'
      ).then((m) => m.FinancialVerificationsModule),
  },
  {
    path: 'facilitors',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/facilitor/facilitors.module').then(
        (m) => m.FacilitorsModule
      ),
  },
  {
    path: 'property',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/property/property.module').then(
        (m) => m.PropertyModule
      ),
  },
  {
    path: 'background',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        './modules/background-verification/background-verification.module'
      ).then((m) => m.BackgroundVerificationModule),
  },
  {
    path: 'attorney',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/attorney/attorney.module').then(
        (m) => m.AttorneyModule
      ),
  },
  {
    path: 'third-party-attorney',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/third-party-attorney/third-party-attorney.module').then(
        (m) => m.ThirdPartyAttorneyModule
      ),
  },
  {
    path: 'fee',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/fee/fee.module').then((m) => m.FeeModule),
  },
  {
    path: 'category',
    canActivate: [AuthGuard],
    // data:{roles:['admin']},
    data: { roles: ['admin', 'subadmin'] },
    loadChildren: () =>
      import('./modules/category-management/category-management.module').then(
        (m) => m.CategoryManagementModule
      ),
  },
  {
    path: 'transaction',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./modules/transaction/transaction.module').then(
        (m) => m.TransactionModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
