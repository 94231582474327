import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  
  
  constructor(public dialog:MatDialog, private sideNavService : SharedService) { }

  ngOnInit() {
   this.getFancialDoc()
  }

  docsData:any =''
  getFancialDoc() {   
    this.sideNavService.financialDocCount().subscribe(res => {
      this.docsData = res.data.data;
      console.log('getFancialDoc ',this.docsData);
    
    }, err => {
      console.log(err)
    });
  }
   
}
