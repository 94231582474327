<div class="wrapper ">
    <app-sidebar></app-sidebar>
    <div class="main-panel">
      <!-- Navbar -->
      <app-header></app-header>
      <div class="content">
        <div class="container-fluid">
          <div class="row" style="margin-top:-50px;">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-primary">
                  <h4 class="card-title ">Feedback List</h4>
                  <!-- <p class="card-category">Here is a subtitle for this table</p> -->
                </div>

                <div class="card-body">
                    <div class="row">
                      <div class="col-md-4">
                        <mat-form-field>
                          <input
                            matInput
                            (keyup)="applyFilter($event.target.value)"
                            placeholder="Search"
                          />
                        </mat-form-field>
                      </div>
                      <div class="col-md-4"></div>
                      <!-- <div class="col-md-4">
                        <button type="button" (click)="openDialog(1)" class="btn btn-primary float-right">
                          Add Category
                        </button>
                      </div> -->
                    </div>
                    <div class="table-responsive video_tab">
                      <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" matSort>
                          <ng-container matColumnDef="photo">
                            <th
                              class="pl-0"
                              mat-header-cell
                              *matHeaderCellDef
                              mat-sort-header
                            >
                              Photo
                            </th>
                            <td
                              class="pl-0 text-center"
                              mat-cell
                              *matCellDef="let row"
                            >
                             
                              <img                                
                                [src]="row.feedbackUser.photo"
                                [width]="80"
                                [height]="80"
                              />
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="name">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Name
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let row">
                              {{ row.feedbackUser.first_name }}  {{ row.feedbackUser.last_name }}
                            </td>
                          </ng-container>
                          <ng-container matColumnDef="subject">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>
                              Subject
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let row">
                              {{ row.subject }}
                            </td>
                          </ng-container>    
                          <!-- ID Column -->
                          <ng-container matColumnDef="feedback">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              mat-sort-header
                              style="width: 25%;"
                            >
                              Feedback
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let row">
                              {{ row.feedback }}
                            </td>
                          </ng-container>
    
                        
                          <ng-container matColumnDef="email">
                            <th
                              mat-header-cell
                              *matHeaderCellDef
                              mat-sort-header
                              style="width: 15%;"
                            >
                              Email
                            </th>
                            <td class="text-center" mat-cell *matCellDef="let row">
                              {{ row.email }}
                            </td>
                          </ng-container>                      
                       
                          <ng-container matColumnDef="action">
                            <th
                              class="pr-0"
                              mat-header-cell
                              *matHeaderCellDef
                              mat-sort-header
                            >
                              Action
                            </th>
                            <td
                              class="text-center pr-0"
                              mat-cell
                              *matCellDef="let row; let i = index"
                            >     
                                             
                              <button
                                class="btn btn-sm btn-danger"
                                (click)="deleteFeedback(row)"
                              >
                                <i class="material-icons">delete_outline </i>
                              </button>
                            </td>
                           
                          </ng-container>                     
    
                          <tr
                            mat-header-row
                            *matHeaderRowDef="displayedColumns"
                          ></tr>
                          <tr
                            mat-row
                            *matRowDef="let row; columns: displayedColumns"
                          ></tr>
                        </table>
    
                        <mat-paginator
                          [pageSizeOptions]="[10, 30, 50, 100]"
                        ></mat-paginator>
                      </div>
                    </div>
                  </div>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  