import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ResetPasswordService } from '../services/reset-password.service'
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  _id: string;
  invalid: any = true;
  submitted = false;
  email: any;
  password: any;
  resetPasswordForm: FormGroup;
  constructor(private activatedRoute: ActivatedRoute, private resetPasswordService: ResetPasswordService, private _route: Router, private formBuilder: FormBuilder, private snack: MatSnackBar, private _matSnackBar: MatSnackBar, private _router: Router) { }

  ngOnInit() {
    if (this._route.url.indexOf('_id') < 1) {
      this._router.navigate(['/login']);
    }
    this.buildForm();
    this.activatedRoute.queryParams.subscribe(params => {
      this._id = params['_id'];
      console.log(this._id, "hello ID"); // Print the parameter to the console. 
    });
  }

  buildForm() {
    this.resetPasswordForm = this.formBuilder.group({
      new_password: ['', [Validators.required, Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]],
      confirm_password: ['', [Validators.required, Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]]
    });
  }
  get f() {
    return this.resetPasswordForm.controls;
  }
  saveDetails() {
    this.submitted = true;
    console.log(this.resetPasswordForm.value)
    if (this.resetPasswordForm.invalid) {
      console.log(this.resetPasswordForm.invalid)
      return;
    }
    if (this.resetPasswordForm.value.newPassword != this.resetPasswordForm.value.confirmPassword) {
      this._matSnackBar.open('New Password and Confirm Password mismatched.', 'Close', {
        duration: 5000,
      });
      return;
    }
    this.resetPasswordForm.value.email = "admin@facilitor.com";
    let params = {
      _id: this._id,
      new_password: this.resetPasswordForm.value.new_password,
      confirm_password: this.resetPasswordForm.value.confirm_password
    }

    this.resetPasswordService.resetPassword(params).subscribe(
      result => {
        this._router.navigate(['/login']);

        this._matSnackBar.open(result.message, 'Close', {
          duration: 3000,
        });

      },
      err => {
        //console.log(err);
        if (err.status == 409) {
          this._matSnackBar.open(err.error.message, 'Close', {
            duration: 3000,
          });
        }
        else {
          this._matSnackBar.open(err.error.message, 'Close', {
            duration: 3000,
          });
        }
      }
    );
  }



}
