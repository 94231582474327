import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CommanService } from '../../../../services/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
})
export class ChangepasswordComponent {
  changePasswordForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private _commonService: CommanService,
    private _matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.changePasswordForm = this.formBuilder.group({
      confirm_password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]],
      new_password: ['', [Validators.required, Validators.minLength(6), Validators.pattern('^(?=.{6,})(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&+=]).*$')]],
      old_password: ['', [Validators.required, Validators.minLength(6)]],
    });
  }
  get f() {
    return this.changePasswordForm.controls;
  }
  refresh() {
    this._router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this._router.navigate(['/login']);
    })
  }
  saveDetails() {
    this.submitted = true;
    if (this.changePasswordForm.invalid) {
      return;
    }
    if (this.changePasswordForm.value.new_password != this.changePasswordForm.value.confirm_password) {
      this._matSnackBar.open('New Password and Confirm Password mismatched.', 'Close', {
        duration: 5000,
      });
      return;
    }
    if (this.changePasswordForm.value.new_password == this.changePasswordForm.value.old_password) {
      this._matSnackBar.open('New Password must be different than the old password.', 'Close', {
        duration: 5000,
      });
      return;
    }
    this.changePasswordForm.value.email = "admin@boba.com";
    let params = {
      old_password: this.changePasswordForm.value.old_password,
      new_password: this.changePasswordForm.value.new_password
    }
    this._commonService.changePassword(params).subscribe(
      (result) => {
        console.log("result")
        if(result.status == 200){
          this.refresh();
          this._router.navigate(['/login']);
          this._matSnackBar.open(result.message, 'Close', {
            duration: 3000,
          });
        }
        if(result.status == 409){
          // this.refresh();
          // this._router.navigate(['/login']);
          this._matSnackBar.open(result.message, 'Close', {
            duration: 3000,
          });
        }
       
      },
      err => {
        console.log(err,"err");
        this._matSnackBar.open(err.error.message, 'Close', {
          duration: 3000,
        });
      }
    );
  }
}
