import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CommanService } from "../../../../services/shared.service";
import { MatSnackBar } from '@angular/material/snack-bar';
import { AbstractControl } from "@angular/forms";
export function removeSpaces(control: AbstractControl) {
  if (control && control.value && !control.value.replace(/\s/g, "").length) {
    control.setValue("");
  }
  return null;
}
@Component({
  selector: "app-broadcasting",
  templateUrl: "./broadcasting.component.html",
  styleUrls: ["./broadcasting.component.css"]
})
export class BroadcastingComponent  {
  broadcastForm: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private _commonService: CommanService,
    private _matSnackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.buildForm();
  }
  //use removeSpaces custom validator for restricting spaces
  // message: ["", [Validators.required, removeSpaces]]

  buildForm() {
    this.broadcastForm = this.formBuilder.group({
      title: ["", [Validators.required, removeSpaces]],
      message: ["", [Validators.required,removeSpaces]]
    });
  }
  get f() {
    return this.broadcastForm.controls;
  }
  saveDetails() {
    this.submitted = true;
    console.log(this.broadcastForm.value);
    if (this.broadcastForm.invalid) {
      return;
    }

    // alert(this.broadcastForm.value.title);
    // alert(this.broadcastForm.value.message);
    if (
      this.broadcastForm.value.title == "" ||
      this.broadcastForm.value.message == ""
    )
      return;

    this._commonService.pushBroadCast(this.broadcastForm.value).subscribe(
      result => {
        this._matSnackBar.open(result.message, "Close", {
          duration: 3000
        });
        this.broadcastForm.patchValue({
          title: "",
          message: ""
        });
      },
      err => {
        this._matSnackBar.open(err.error.message, "Close", {
          duration: 3000
        });
      }
    );
  }
}
