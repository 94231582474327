import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../../environments/environment";
import { HttpcommanService } from '../../../services/httpshared.service';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private http: HttpClient, private _http: HttpcommanService) {}
  uploadProfilePicture(data){
    return this._http.putCall('admin/upload-image',data);  
  }
 getProfilePicture(){
    return this._http.getCall('admin/get-profile-pic');  
  }
  updateAdminProfile(data){
    return this._http.putCall(`admin/update-admin-profile`,data);
  }

  financialDocCount(){
    return this._http.getCall(`admin/dashboard/unread-document-count`);
  }
}
