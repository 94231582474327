import { Component, OnInit,ViewChild } from '@angular/core';
import {CommanService} from '../../../../services/shared.service'; 
import { MatTableDataSource } from "@angular/material/table";
import {  MatSort } from "@angular/material/sort";
import {  MatPaginator } from "@angular/material/paginator";
import Swal from "sweetalert2";
@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent {
  public feedbacks: any = [];
  public notFound = true;
  public displayedColumns: any;
  public dataSource: any;
  public color = "accent";
  public checked = true;
  public disabled = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private _commanService:CommanService) { }

  ngOnInit(): void {
    this.getFeedbacks();
  }

  getFeedbacks():void {
    this._commanService.getAllFeedbacks().subscribe(success=>{
      console.log(success,'dfdfadfadf');      
        this.feedbacks = success.data.list;
        this.displayedColumns = [
          "photo",
          "name",
          "subject",
          "feedback",        
          "email",         
          "action"
        ];
        this.dataSource = new MatTableDataSource(this.feedbacks);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
    },error=>{
      console.log(error);
    });
  }  
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  //Delete feedback code start from here
  deleteFeedback(feedbackData: any) {  
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success",
        cancelButton: "btn btn-danger"
      },
      buttonsStyling: false
    });

    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Delete!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true
      })
      .then(result => {
        if (result.value) {
          if (this.startDeletingRestaurant(feedbackData.id)) {
            swalWithBootstrapButtons.fire(
              "Done!",
              "Review has been deleted.",
              "success"
            );
          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
        }
      });
  }

  startDeletingRestaurant(feedbackId:any) {
    this._commanService.deleteFeedback(feedbackId).subscribe(success => {
      this.getFeedbacks();
    });
    return true;
  }

}
