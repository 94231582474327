<div class="wrapper">
  <!-- <app-sidebar></app-sidebar> -->
  <div
    class="main-panel"
    style="
      background-image: url('../../assets/img/admin-bg.png');
      background-size: cover;
    "
  >
    <!-- Navbar -->
    <!-- <app-header></app-header> -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header card-header-primary">
                <h4 class="card-title">Reset Password</h4>
                <!-- <p class="card-category">Here is a subtitle for this table</p> -->
              </div>
              <div class="card-body">
                <form [formGroup]="resetPasswordForm">
                  <div class="row"></div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="bmd-label-floating">New Password</label>
                        <input
                          (keydown.space)="$event.preventDefault()"
                          type="password"
                          class="form-control"
                          formControlName="new_password"
                          name="new_password"
                        />
                      </div>
                      <div
                        *ngIf="submitted && f.new_password.errors"
                        class="text-left"
                      >
                        <p
                          *ngIf="f.new_password.errors.required"
                          class="validation_color text-danger"
                        >
                          New Password is required !
                        </p>
                        <p
                          *ngIf="f.new_password.errors.minlength"
                          class="validation_color text-danger"
                        >
                          Password must be at least 6 characters.
                        </p>
                        <p
                          *ngIf="f?.new_password?.errors?.pattern"
                          class="validation_color text-danger"
                        >
                          Password must contain one upper case, one lower case
                          and one special character.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label class="bmd-label-floating"
                          >Confirm Password</label
                        >
                        <input
                          type="password"
                          class="form-control"
                          formControlName="confirm_password"
                          name="confirm_password"
                          (keydown.space)="$event.preventDefault()"
                        />
                      </div>
                      <div
                        *ngIf="submitted && f.confirm_password.errors"
                        class="text-left"
                      >
                        <p
                          *ngIf="f.confirm_password.errors.required"
                          class="validation_color text-danger"
                        >
                          Confirm Password is required !
                        </p>
                        <p
                          *ngIf="f.confirm_password.errors.minlength"
                          class="validation_color text-danger"
                        >
                          Password must be at least 6 characters.
                        </p>
                        <p
                          *ngIf="f?.confirm_password?.errors?.pattern"
                          class="validation_color text-danger"
                        >
                          Password must contain one upper case, one lower case
                          and one special character.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <!-- <div class="col-md-1"></div> -->
                    <div class="col-md-3">
                      <div class="form-group">
                        <button
                          class="btn btn-primary btn-md"
                          (click)="saveDetails()"
                        >
                          Reset Password
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
