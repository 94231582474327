import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpcommanService } from "./httpshared.service";
import * as $ from 'jquery'
//declare var $:any;
@Injectable({
  providedIn: "root"
})
export class CommanService {
  constructor(
    private http: HttpClient,
    private httpService: HttpcommanService
  ) {}
  changePassword(params: any) {
    return this.httpService.patchCall('admin/auth/reset-password', params);
   
  }

  pushBroadCast(data: any) {
    return this.httpService.postCall('pushBroadcast', data);   
  }


  //Access from feedback component
  getAllFeedbacks(){
    return this.httpService.getCall('admin/feedbacks');
  }
  //Accessing from feedback component
  deleteFeedback(feedbackId:any){
    return this.httpService.deleteCall('admin/deleteFeedback',feedbackId);
  }
  scrollToTopZero(){
    $({myScrollTop:window.pageYOffset}).animate({myScrollTop:0}, {
      duration: 100,
      easing: 'swing',
      step: function(val:any) {
        window.scrollTo(0, val);
      }
    });
  }

}
