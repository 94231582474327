<div class="wrapper">
  <!-- <app-sidebar></app-sidebar> -->
  <div
    class="main-panel"
    style="
      background-image: url('../../assets/img/admin-bg.png');
      background-size: cover;
      background-position: top center;
    "
  >
    <!-- Navbar -->
    <!-- <app-header></app-header> -->
    <div class="content">
      <div
        class="container-fluid"
        style="display: flex; justify-content: flex-end"
      >
        <div
          class="row"
          style="margin-top: -50px; max-width: 500px; width: 100%"
        >
          <div class="col-md-12">
            <div class="card">
              <div class="card-header card-header-primary">
                <h4 class="card-title">Reset Password</h4>
                <!-- <p class="card-category">Here is a subtitle for this table</p> -->
              </div>
              <div class="card-body">
                <form [formGroup]="resetPasswordForm">
                  <div class="row">
                    <!-- <div class="col-md-1"></div> -->
                    <div class="col-12">
                      <div class="form-group">
                        <label class="bmd-label-floating">Enter Email</label>
                        <input
                          type="email"
                          class="form-control"
                          formControlName="email"
                          name="email"
                        />
                      </div>
                      <div
                        *ngIf="submitted && f.email.errors"
                        class="text-left"
                      >
                        <div
                          *ngIf="f.email.errors.required"
                          class="validation_color text-danger"
                        >
                          Email Is Required !
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="row">
                      <div class="col-md-1"></div>
                      <div class="col-md-6">
                        <div class="form-group">
                          <label class="bmd-label-floating">Confirm Password</label>
                          <input
                            type="password"
                            class="form-control"
                            formControlName="confirmPassword"
                            name="confirmPassword"
                            (keydown.space)="$event.preventDefault();"
                          />
                        </div>
                        <div *ngIf="submitted && f.confirmPassword.errors" class=" text-left">
                          <div *ngIf="f.confirmPassword.errors.required" class="validation_color text-danger">
                            Confirm Password is required !
                          </div>
                          <div *ngIf="f.confirmPassword.errors.minlength" class="validation_color text-danger">
                             Password must be at least 6 characters.
                          </div>
                        </div>
                      </div>
                    </div> -->

                  <div class="row">
                    <!-- <div class="col-md-1"></div> -->
                    <div class="col-12">
                      <div class="form-group">
                        <button
                          class="btn btn-primary btn-md"
                          (click)="saveDetails()"
                        >
                          Send Mail
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
