import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtService } from '../services/jwt.services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {ForgotPasswordService} from '../services/forgot-password.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  invalid: any = true;
  submitted = false;
  email: any;
  password: any;
  resetPasswordForm: FormGroup;
  constructor(private forgotPasswordService: ForgotPasswordService, private _route: Router, private formBuilder: FormBuilder, private snack: MatSnackBar, private _matSnackBar: MatSnackBar, private _router: Router) { }

  ngOnInit() {
    this.buildForm();
  }

  buildForm() {
    this.resetPasswordForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }
  get f() {
    return this.resetPasswordForm.controls;
  }
  saveDetails() {
    this.submitted = true;
    if (this.resetPasswordForm.invalid) {
      return;
    }
   
    let params={
      email:this.resetPasswordForm.value.email
    }
    
    this.forgotPasswordService.forgotPassword(params).subscribe(
      result => {
        this._matSnackBar.open(result.message, 'Close', {
          duration: 3000,
        });
      //  this._router.navigate(['/login']);
      },
      err => {
        //console.log(err);
        this._matSnackBar.open(err.error.message, 'Close', {
          duration: 3000,
        });
      }
    );
  }

}
